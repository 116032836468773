.categoryContainer {
  display: flex;
  justify-content: center;
  padding: 72px;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}

.categoryTxt1 {
  color: rgb(80, 78, 75);
  font-size: 44px;
  font-weight: 600;
  text-align: center;
  font-family: open sans;
}

.categoryTxt2 {
  color: rgb(243, 240, 235);
  font-size: 44px;
  font-weight: 600;
  text-align: center;
  font-family: open sans;
}

.categoryBox1 {
  width: 100%;
  height: 260px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 64px 0px 0px 0px;
}

.categoryBox1-div1 {
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 16px 16px 16px;
  border-radius: 16px;
  font-size: 22px;
  font-family: open sans;
  font-weight: 600;
  letter-spacing: 0.3;
  box-shadow: 1px;
  color: rgb(80, 78, 75);
  border: 1px solid #bcbcbc;
  box-shadow: 0px 0px 9.300000190734863px 0px #23c55263;

  /* background-image: linear-gradient(180deg, red, yellow); */
}

.categoryBox1-div1 div:nth-child(2) {
  font-size: 14px;
  font-family: open sans;
  font-weight: 600;
  letter-spacing: 0.3;
  box-shadow: 1px;
  color: #fff;
}

.categoryBox1-div2 {
  width: 63%;
  height: 100%;
  padding: 16px;
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 0px 0px 0px;
  color: rgb(80, 78, 75);
  border: 1px solid #bcbcbc;
  box-shadow: 0px 0px 9.300000190734863px 0px #23c55263;

  /* background-image: linear-gradient(180deg, red, yellow); */
}

.categoryBox1-div2 div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  font-size: 24px;
  font-family: open sans;
  font-weight: 600;
  letter-spacing: 0.3;
}

.categoryBox2 {
  width: 100%;
  height: 530px;
  display: flex;
  justify-content: space-between;
  margin: 32px 0px 32px 0px;
}

.categoryBox2-div1 {
  width: 48%;
  height: 100%;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border-radius: 16px;
  margin: 0px 0px 0px 0px;
  font-size: 20px;
  font-family: open sans;
  font-weight: 600;
  letter-spacing: 0.3;
  color: rgb(80, 78, 75);
  border: 1px solid #bcbcbc;
  box-shadow: 0px 0px 9.300000190734863px 0px #23c55263;
  /* background-image: linear-gradient(white, yellow, green); */
}

.categoryBox2-div1 div:nth-child(2) {
  font-size: 18px;
  padding-top: 16px;
  color: #fff;
}

.categoryBox2-div {
  display: flex;
  flex-wrap: wrap;
  width: 48%;
  height: 580px;
  justify-content: space-between;
}

.categoryBox2-div2 {
  width: 42.5%;
  height: 238px;
  padding: 16px;
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: 0px 0px 0px 0px;
  font-size: 22px;
  font-family: open sans;
  font-weight: 600;
  letter-spacing: 0.3;
  box-shadow: 1px;
  color: rgb(80, 78, 75);
  border: 1px solid #bcbcbc;
  box-shadow: 0px 0px 9.300000190734863px 0px #23c55263;
  /* background-image: linear-gradient(white, yellow, green); */
}

.categoryBox2-div2 div:nth-child(2) {
  font-size: 14px;
  font-family: open sans;
  font-weight: 600;
  letter-spacing: 0.3;
  box-shadow: 1px;
  color: #fff;
}

.categoryBox3 {
  width: 100%;
  height: 260px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin: 32px 0px 0px 0px;
}

.categoryBox3-div1 {
  width: 22%;
  height: 260px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 16px;
  padding: 16px;
  font-size: 20px;
  font-family: open sans;
  font-weight: 600;
  letter-spacing: 0.3;
  color: rgb(80, 78, 75);
  border: 1px solid #bcbcbc;
  box-shadow: 0px 0px 9.300000190734863px 0px #23c55263;
  /* background-image: linear-gradient(green, violet, skyblue); */
  color: rgb(73, 71, 68);
}

.categoryBox3-div1 div:nth-child(2) {
  font-size: 14px;
  font-family: open sans;
  font-weight: 600;
  letter-spacing: 0.3;
  box-shadow: 1px;
  color: #fff;
}

.categoryBox3-div2 {
  width: 45%;
  height: 260px;
  display: flex;
  justify-content: space-between;
  border-radius: 16px;
  padding: 16px;
  background-image: url("/public/assets/category.png");
  background-size: cover;
}

.categoryTxt5 {
  display: flex;
  width: 100%;
  margin: 100px 0px 0px 0px;
}

.categoryTxt3 {
  color: rgb(243, 240, 235);
  font-size: 44px;
  font-weight: 600;
  text-align: center;
  font-family: open sans;
}

.categoryTxt4 {
  color: rgb(243, 240, 235);
  font-size: 44px;
  font-weight: 600;
  text-align: center;
  font-family: open sans;
}

.categoryTxt6 {
  color: rgb(80, 78, 75);
  font-size: 24px;
  font-weight: 600;
  width: 100%;
  font-family: open sans;
  margin: 24px 0px 0px 0px;
}

@media screen and (max-width: 1440px) {
  .categoryContainer {
    display: flex;
    justify-content: center;
    padding: 72px;
    align-items: center;
    flex-direction: column;
  }

  .categoryTxt1 {
    color: rgb(80, 78, 75);
    font-size: 44px;
    font-weight: 600;
    text-align: center;
    font-family: open sans;
  }

  .categoryTxt2 {
    color: rgb(243, 240, 235);
    font-size: 44px;
    font-weight: 600;
    text-align: center;
    font-family: open sans;
  }

  .categoryBox1 {
    width: 100%;
    height: 260px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 64px 0px 0px 0px;
  }

  .categoryBox1-div1 {
    width: 22%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px 16px 16px 16px;
    border-radius: 16px;
    font-size: 18px;
    font-family: open sans;
    font-weight: 600;
    letter-spacing: 0.3;
    box-shadow: 1px;
    color: rgb(80, 78, 75);
    border: 1px solid #bcbcbc;
    box-shadow: 0px 0px 9.300000190734863px 0px #23c55263;
    /* background-image: linear-gradient(180deg, red, yellow); */
  }

  .categoryBox1-div1 div:nth-child(2) {
    font-size: 14px;
    font-family: open sans;
    font-weight: 600;
    letter-spacing: 0.3;
    box-shadow: 1px;
    color: #fff;
  }

  .categoryBox1-div2 {
    width: 71%;
    height: 100%;
    padding: 16px;
    border-radius: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: rgb(80, 78, 75);
    border: 1px solid #bcbcbc;
    box-shadow: 0px 0px 9.300000190734863px 0px #23c55263;
    /* background-image: linear-gradient(180deg, red, yellow); */
    margin: 0px 0px 0px 0px;
  }

  .categoryBox1-div2 div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    font-size: 20px;
    font-family: open sans;
    font-weight: 600;
    letter-spacing: 0.3;
    border: 0px solid #fff;
  }

  .categoryBox2 {
    width: 100%;
    height: 530px;
    display: flex;
    justify-content: space-between;
    margin: 32px 0px 32px 0px;
  }

  .categoryBox2-div1 {
    width: 48%;
    height: 100%;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    color: rgb(80, 78, 75);
    border: 1px solid #bcbcbc;
    box-shadow: 0px 0px 9.300000190734863px 0px #23c55263;
    /* background-image: linear-gradient(white, yellow, green); */
    border-radius: 16px;
    margin: 0px 0px 0px 0px;
    font-size: 20px;
    font-family: open sans;
    font-weight: 600;
    letter-spacing: 0.3;
  }

  .categoryBox2-div1 div:nth-child(2) {
    font-size: 18px;
    padding-top: 16px;
    color: #fff;
  }

  .categoryBox2-div {
    display: flex;
    flex-wrap: wrap;
    width: 48%;
    height: 580px;
    justify-content: space-between;
  }

  .categoryBox2-div2 {
    width: 42.5%;
    height: 238px;
    padding: 16px;
    border-radius: 16px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    color: rgb(80, 78, 75);
    border: 1px solid #bcbcbc;
    box-shadow: 0px 0px 9.300000190734863px 0px #23c55263;
    /* background-image: linear-gradient(white, yellow, green); */
    margin: 0px 0px 0px 0px;
  }

  .categoryBox2-div2 div:nth-child(2) {
    font-size: 14px;
    font-family: open sans;
    font-weight: 600;
    letter-spacing: 0.3;
    box-shadow: 1px;
    color: #fff;
  }

  .categoryBox3 {
    width: 100%;
    height: 260px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin: 32px 0px 0px 0px;
  }

  .categoryBox3-div1 {
    width: 22%;
    height: 260px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 20px;
    font-family: open sans;
    font-weight: 600;
    letter-spacing: 0.3;
    /* background-color: #fff; */
    color: rgb(73, 71, 68);
    border-radius: 16px;
    padding: 16px;
  }

  .categoryBox3-div1 div:nth-child(2) {
    font-size: 14px;
    font-family: open sans;
    font-weight: 600;
    letter-spacing: 0.3;
    box-shadow: 1px;
    color: #fff;
  }

  .categoryBox3-div2 {
    width: 45%;
    height: 260px;
    display: flex;
    justify-content: space-between;
    border-radius: 16px;
    padding: 16px;
    background-image: url("/public/assets/category.png");
    background-size: cover;
  }

  .categoryTxt5 {
    display: flex;
    width: 100%;
    margin: 100px 0px 0px 0px;
  }

  .categoryTxt3 {
    color: rgb(243, 240, 235);
    font-size: 44px;
    font-weight: 600;
    text-align: center;
    font-family: open sans;
  }

  .categoryTxt4 {
    color: rgb(243, 240, 235);
    font-size: 44px;
    font-weight: 600;
    text-align: center;
    font-family: open sans;
  }

  .categoryTxt6 {
    color: rgb(80, 78, 75);
    font-size: 24px;
    font-weight: 600;
    width: 100%;
    font-family: open sans;
    margin: 24px 0px 0px 0px;
  }
}

@media screen and (max-width: 430px) {
  .categoryContainer {
    display: flex;
    justify-content: center;
    padding: 0px 32px 32px 32px;
    align-items: center;
    flex-direction: column;
  }

  .categoryTxt1 {
    color: rgb(80, 78, 75);
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    font-family: "Courier New", Courier, monospace;
  }

  .categoryTxt2 {
    color: rgb(243, 240, 235);
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    font-family: "Courier New", Courier, monospace;
  }

  .categoryBox1 {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    margin: 16px 0px 0px 0px;
  }

  .categoryBox1-div1 {
    width: 100%;
    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 24px 16px 24px 16px;
    border-radius: 16px;
    font-size: 14px;
    font-family: Roboto;
    font-weight: 600;
    letter-spacing: 0.3;
    box-shadow: 1px;
    color: rgb(80, 78, 75);
    border: 1px solid #bcbcbc;
    box-shadow: 0px 0px 9.300000190734863px 0px #23c55263;
    /* background-image: linear-gradient(180deg, red, yellow); */
  }

  .categoryBox1-div1 div:nth-child(2) {
    font-size: 12px;
    font-family: "Courier New", Courier, monospace;
    font-weight: 600;
    letter-spacing: 0.3;
    box-shadow: 1px;
    color: #fff;
  }

  .categoryBox1-div2 {
    width: 100%;
    height: 400px;
    padding: 16px;
    border-radius: 16px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    color: rgb(80, 78, 75);
    border: 1px solid #bcbcbc;
    box-shadow: 0px 0px 9.300000190734863px 0px #23c55263;
    /* background-image: linear-gradient(180deg, red, yellow); */
    margin: 16px 0px 0px 0px;
  }

  .categoryBox1-div2 div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    font-size: 14px;
    font-family: Roboto;
    font-weight: 600;
    letter-spacing: 0.3;
  }

  .categoryTxt5 {
    display: flex;
    width: 100%;
    margin: 32px 0px 0px 0px;
    flex-direction: column;
  }

  .categoryTxt3 {
    color: rgb(243, 240, 235);
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    font-family: "Courier New", Courier, monospace;
  }

  .categoryTxt4 {
    color: rgb(243, 240, 235);
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    font-family: "Courier New", Courier, monospace;
  }

  .categoryTxt6 {
    color: rgb(80, 78, 75);
    font-size: 16px;
    font-weight: 600;
    width: 100%;
    font-family: "Courier New", Courier, monospace;
    margin: 24px 0px 0px 0px;
  }

  .categoryBox2 {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    margin: 0px 0px 0px 0px;
    padding: 16px 32px 0px 32px;
    align-items: center;
    flex-direction: column;
  }

  .categoryBox2-div1 {
    width: 100%;
    height: 100%;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    border-radius: 16px;
    margin: 0px 0px 0px 0px;
    font-size: 14px;
    font-family: roboto;
    font-weight: 600;
    letter-spacing: 0.3;
    color: rgb(80, 78, 75);
    border: 1px solid #bcbcbc;
    box-shadow: 0px 0px 9.300000190734863px 0px #23c55263;
    /* background-image: linear-gradient(white, yellow, green); */
  }

  .categoryBox2-div1 div:nth-child(2) {
    font-size: 12px;
    padding-top: 16px;
    color: #fff;
    font-family: "Courier New", Courier, monospace;
  }

  .categoryBox2-div {
    display: flex;
    flex-wrap: wrap;
    width: 110%;
    height: 410px;
    justify-content: space-between;
    padding-top: 12px;
  }

  .categoryBox2-div2 {
    width: calc(42% - 10px);
    height: 160px;
    padding: 16px;
    border-radius: 16px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    color: rgb(80, 78, 75);
    border: 1px solid #bcbcbc;
    box-shadow: 0px 0px 9.300000190734863px 0px #23c55263;
    /* background-image: linear-gradient(white, yellow, green); */
    margin: 0px 0px 0px 0px;
    font-size: 14px;
    font-family: Roboto;
    font-weight: 600;
    letter-spacing: 0.3;
    box-shadow: 1px;
  }

  .categoryBox2-div2 div:nth-child(2) {
    font-size: 12px;
    font-family: "Courier New", Courier, monospace;
    font-weight: 600;
    letter-spacing: 0.3;
    box-shadow: 1px;
    color: #fff;
  }

  .categoryBox3 {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    margin: 0px 0px 0px 0px;
    gap: 12px;
  }

  .categoryBox3-div1 {
    width: 100%;
    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 16px;
    padding: 16px;
    font-size: 14px;
    font-family: Roboto;
    font-weight: 600;
    letter-spacing: 0.3;
    box-shadow: 1px;
    /* background-color: #fff; */
  }

  .categoryBox3-div1 div:nth-child(2) {
    font-size: 12px;
    font-family: "Courier New", Courier, monospace;
    font-weight: 600;
    letter-spacing: 0.3;
    box-shadow: 1px;
    color: #fff;
  }

  .categoryBox3-div2 {
    width: 100%;
    height: 140px;
    display: flex;
    justify-content: space-between;
    border-radius: 16px;
    padding: 16px;
    background-image: url("/public/assets/category.png");
    background-size: cover;
  }
}

@media screen and (max-width: 330px) {
  .categoryContainer {
    display: flex;
    justify-content: center;
    padding: 0px 32px 32px 32px;
    align-items: center;
    flex-direction: column;
  }

  .categoryTxt1 {
    color: rgb(80, 78, 75);
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    font-family: "Courier New", Courier, monospace;
  }

  .categoryTxt2 {
    color: rgb(243, 240, 235);
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    font-family: "Courier New", Courier, monospace;
  }

  .categoryBox1 {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    margin: 16px 0px 0px 0px;
  }

  .categoryBox1-div1 {
    width: 100%;
    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 24px 16px 24px 16px;
    border-radius: 16px;
    font-size: 14px;
    font-family: Roboto;
    font-weight: 600;
    letter-spacing: 0.3;
    box-shadow: 1px;
    color: rgb(80, 78, 75);
    border: 1px solid #bcbcbc;
    box-shadow: 0px 0px 9.300000190734863px 0px #23c55263;
    /* background-image: linear-gradient(180deg, red, yellow); */
  }

  .categoryBox1-div1 div:nth-child(2) {
    font-size: 12px;
    font-family: "Courier New", Courier, monospace;
    font-weight: 600;
    letter-spacing: 0.3;
    box-shadow: 1px;
    color: #fff;
  }

  .categoryBox1-div2 {
    width: 100%;
    height: 400px;
    padding: 16px;
    border-radius: 16px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    /* background-color: #fff; */
    margin: 16px 0px 0px 0px;
  }

  .categoryBox1-div2 div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    font-size: 14px;
    font-family: Roboto;
    font-weight: 600;
    letter-spacing: 0.3;
  }

  .categoryTxt5 {
    display: flex;
    width: 100%;
    margin: 32px 0px 0px 0px;
    flex-direction: column;
  }

  .categoryTxt3 {
    color: rgb(243, 240, 235);
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    font-family: "Courier New", Courier, monospace;
  }

  .categoryTxt4 {
    color: rgb(243, 240, 235);
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    font-family: "Courier New", Courier, monospace;
  }

  .categoryTxt6 {
    color: rgb(80, 78, 75);
    font-size: 16px;
    font-weight: 600;
    width: 100%;
    font-family: "Courier New", Courier, monospace;
    margin: 24px 0px 0px 0px;
  }

  .categoryBox2 {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    margin: 0px 0px 0px 0px;
    padding: 16px 32px 0px 32px;
    align-items: center;
    flex-direction: column;
  }

  .categoryBox2-div1 {
    width: 100%;
    height: 100%;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    border-radius: 16px;
    margin: 0px 0px 0px 0px;
    font-size: 14px;
    font-family: roboto;
    font-weight: 600;
    letter-spacing: 0.3;
    color: rgb(80, 78, 75);
    border: 1px solid #bcbcbc;
    box-shadow: 0px 0px 9.300000190734863px 0px #23c55263;
    /* background-image: linear-gradient(white, yellow, green); */
  }

  .categoryBox2-div1 div:nth-child(2) {
    font-size: 12px;
    padding-top: 16px;
    color: #fff;
    font-family: "Courier New", Courier, monospace;
  }

  .categoryBox2-div {
    display: flex;
    flex-wrap: wrap;
    width: 110%;
    height: 410px;
    justify-content: space-between;
    padding-top: 12px;
  }

  .categoryBox2-div2 {
    width: calc(40% - 10px);
    height: 160px;
    padding: 16px;
    border-radius: 16px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    color: rgb(80, 78, 75);
    border: 1px solid #bcbcbc;
    box-shadow: 0px 0px 9.300000190734863px 0px #23c55263;
    /* background-image: linear-gradient(white, yellow, green); */
    margin: 0px 0px 0px 0px;
    font-size: 14px;
    font-family: Roboto;
    font-weight: 600;
    letter-spacing: 0.3;
    box-shadow: 1px;
  }

  .categoryBox2-div2 div:nth-child(2) {
    font-size: 10px;
    font-family: "Courier New", Courier, monospace;
    font-weight: 600;
    letter-spacing: 0.3;
    box-shadow: 1px;
    color: #fff;
  }

  .categoryBox3 {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    margin: 0px 0px 0px 0px;
    gap: 12px;
  }

  .categoryBox3-div1 {
    width: 100%;
    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 16px;
    padding: 16px;
    font-size: 14px;
    font-family: Roboto;
    font-weight: 600;
    letter-spacing: 0.3;
    box-shadow: 1px;
    background-color: #fff;
  }

  .categoryBox3-div1 div:nth-child(2) {
    font-size: 12px;
    font-family: "Courier New", Courier, monospace;
    font-weight: 600;
    letter-spacing: 0.3;
    box-shadow: 1px;
    color: #fff;
  }

  .categoryBox3-div2 {
    width: 100%;
    height: 140px;
    display: flex;
    justify-content: space-between;
    border-radius: 16px;
    padding: 16px;
    background-image: url("/public/assets/category.png");
    background-size: cover;
  }
}
