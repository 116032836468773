body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    "open-sans", monospace;
}

::-webkit-scrollbar {
  width: 0px;
  height: 0px; 
  /* Set the width of the scrollbar */
}


::-webkit-scrollbar-thumb {
  background-color: #e7b1b1; /* Set the color of the thumb */
}

::-webkit-scrollbar-track {
  background-color: #f0f0f0; /* Set the color of the track */
}
