.headerContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 65px;
  z-index: 999;
  box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
  background-color: #000 !important;
  padding: 0px;
  animation: slideIn 2s ease-out;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

a:link {
  color: #1f2021;
  background-color: transparent;
  text-decoration: none;
}

@keyframes slideIn {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

.headerLeft {
  width: auto;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 16px 0px 0px 72px;
}

.logoText {
  color: rgb(70, 67, 67);
  text-decoration: none;
  font-size: 24px;
  letter-spacing: 1px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: open sans;
  cursor: pointer;
  opacity: 1;
}

.logoText:hover {
  opacity: 0.8;
}

.mainLogo {
  height: 45px;
  width: 45px;
  opacity: 1;
  cursor: pointer;
  padding: 0px 4px 0px 4px;
  animation: rotation 8s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.mainLogo:hover {
  opacity: 0.8;
}

.headerRight {
  display: flex;
  justify-content: space-between;
  padding: 16px 72px 0px 0px;
  align-items: center;
}

.headerRightFirsttdiv {
  display: flex;
  justify-content: space-between;
  padding: 0px 16px 0px 0px;
}

.headerRight-div {
  color: #fff;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: 500;
  font-family: open sans;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 32px 0px 0px;
  position: relative;
}

.headerRight-div:hover {
  color: #343434;
}

.headerRight-div-div {
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: #1f2021;
  text-decoration: none;
  font-size: 18px;
  letter-spacing: 1px;
  font-weight: 500;
  font-family: open sans;
  cursor: pointer;
  width: 200px;
  background-color: #adb98e;
  padding: 8px 16px 8px 16px;
  border-radius: 32px;
  opacity: 1;
}

.headerRight-div-div:hover {
  opacity: 0.8;
  color: #fff;
  background-color: #000;
  border: 1px double #343434;
}

.headerDialog {
  width: 800;
  padding: 16px 16px 16px 16px;
  height: 160;
}

.dialogClose {
  width: 100%;
  text-align: right;
}

.dialogClose:active {
  opacity: 0.8;
}

@media only screen and (max-width: 1440px) {
  .headerContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 65px;
    z-index: 999;
    box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
    background-color: #000 !important;
    padding: 0px;
    animation: slideIn 2s ease-out;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .headerLeft {
    width: auto;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 16px 0px 0px 72px;
  }

  .logoText {
    color: rgb(70, 67, 67);
    text-decoration: none;
    font-size: 24px;
    letter-spacing: 1px;
    font-weight: 700;
    text-transform: uppercase;
    font-family: open sans;
    cursor: pointer;
  }

  .logoText:hover {
    opacity: 0.8;
  }

  .mainLogo {
    height: 45px;
    width: 45px;
    opacity: 1;
    cursor: pointer;
    padding: 0px 4px 0px 4px;
    animation: rotation 8s infinite linear;
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }

  .mainLogo:hover {
    opacity: 0.8;
  }

  .headerRight {
    display: flex;
    justify-content: space-between;
    padding: 16px 72px 0px 0px;
    align-items: center;
  }

  .headerRightFirsttdiv {
    display: flex;
    justify-content: space-between;
    padding: 0px 16px 0px 0px;
  }

  .headerRight-div {
    color: #fff;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    letter-spacing: 1px;
    font-weight: 500;
    font-family: open sans;
    cursor: pointer;
    padding: 0px 32px 0px 0px;
    position: relative;
  }

  .headerRight-div:hover {
    color: #343434;
  }

  .headerRight-div-div {
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: #1f2021;
    text-decoration: none;
    font-size: 18px;
    letter-spacing: 1px;
    font-weight: 500;
    font-family: open sans;
    cursor: pointer;
    width: 180px;
    background-color: #adb98e;
    padding: 8px 16px 8px 16px;
    border-radius: 32px;
    opacity: 1;
  }

  .headerRight-div-div:hover {
    opacity: 0.8;
    color: #fff;
    background-color: #000;
    border: 1px double #343434;
  }

  .headerDialog {
    width: 650px;
    padding: 16px 16px 16px 16px;
    animation: fadeInUp 2s ease-out;
  }

  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .dialogClose {
    width: 100%;
    text-align: right;
  }

  .dialogClose:active {
    opacity: 0.8;
  }
}

@media only screen and (max-width: 430px) {
  .headerContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 65px;
    z-index: 999;
    box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
    background-color: #000 !important;
    padding: 0px;
    animation: slideIn 2s ease-out;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .headerLeft {
    width: 40%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0px 0px 0px 8px;
  }

  .logoText {
    color: rgb(163, 155, 155);
    text-decoration: none;
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: 700;
    text-transform: uppercase;
    font-family: 'Courier New', Courier, monospace;
    cursor: pointer;
    opacity: 1;
  }

  .logoText:hover {
    opacity: 0.8;
  }

  .mainLogo {
    height: 25px;
    width: 25px;
    opacity: 1;
    cursor: pointer;
    padding: 0px 4px 0px 4px;
    animation: rotation 8s infinite linear;
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }

  .mainLogo:hover {
    opacity: 0.8;
  }

  .headerRight {
    display: flex;
    justify-content: flex-end;
    width: 65%;
    align-items: center;
    padding: 0px 8px 0px 0px;
  }

  .headerRightFirsttdiv {
    display: flex;
    justify-content: space-between;
    width: 65%;
    padding: 0px 16px 0px 0px;
  }

  .headerRight-div {
    color: #fff;
    text-align: center;
    text-decoration: none;
    font-size: 18px;
    letter-spacing: 1px;
    font-weight: 500;
    font-family: 'Courier New', Courier, monospace;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .headerRight-div:hover {
    color: #343434;
  }

  .headerRight-div-div {
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: #1f2021;
    text-decoration: none;
    font-size: 18px;
    letter-spacing: 1px;
    font-weight: 500;
    font-family: open sans;
    cursor: pointer;
    width: 25%;
    background-color: #adb98e;
    padding: 12px;
    border-radius: 32px;
    opacity: 1;
  }

  .headerRight-div-div:hover {
    opacity: 0.8;
    color: #fff;
    background-color: #000;
    border: 1px double #343434;
  }

  .headerDialog {
    width: 90%;
    padding: 16px 16px 16px 16px;
    height: 160;
  }

  .dialogClose {
    width: 100%;
    text-align: right;
  }

  .dialogClose:active {
    opacity: 0.8;
  }
}

.menuAnima {
  animation: abcd1 2s linear;
}

@keyframes abcd1 {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

.menuDialog {
  width: 800;
  padding: 16px 16px 16px 16px;
  height: 160;
  background-color: transparent !important;
  color: #fff;
  animation: abcd 2s linear;
}

@keyframes abcd {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

.alignMenuItems {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: flex-end;
}
