.map-container {
  height: 100%;
  width: 100%;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.map-container1 {
  height: 100%;
  width: 100%;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
}

.mapContainer {
  width: auto;
  height: 70vh;
  padding: 72px 72px 72px 72px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.map-container2 {
  height: 100%;
  width: 40%;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  /* background-color: #171616; */
  align-items: center;
}

.mapTxt {
  font-size: 36px;
  font-family: open sans;
  font-weight: 600;
  color: rgb(88, 104, 70);
  margin: 0px 0px 0px 0px;
}

.mapText1 {
  width: 400px;
  border: none;
  background-color: #fff9;
  margin: 16px 0px 0px 0px;
  height: 40px;
  font-size: 14px;
  padding: 0px 0px 0px 16px;
  border-radius: 16px;
}

.mapTxtArea {
  width: 384px;
  border: none;
  background-color: #fff9;
  margin: 16px 0px 0px 0px;
  font-size: 16px;
  padding: 16px 16px 16px 16px;
  border-radius: 16px;
}

@media only screen and (max-width: 1440px) {
  .map-container {
    height: 100%;
    width: 100%;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .map-container1 {
    height: 100%;
    width: 100%;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px;
  }

  .mapContainer {
    width: auto;
    height: 70vh;
    padding: 72px 72px 72px 72px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .map-container2 {
    height: 100%;
    width: 40%;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    /* background-color: #171616; */
    align-items: center;
  }

  .mapTxt {
    font-size: 36px;
    font-family: open sans;
    font-weight: 600;
    color: rgb(88, 104, 70);
    margin: 0px 0px 0px 0px;
  }

  .mapText1 {
    width: 400px;
    border: none;
    background-color: #fff9;
    margin: 16px 0px 0px 0px;
    height: 40px;
    font-size: 14px;
    padding: 0px 0px 0px 16px;
    border-radius: 16px;
  }

  .mapTxtArea {
    width: 384px;
    border: none;
    background-color: #fff9;
    margin: 16px 0px 0px 0px;
    font-size: 16px;
    padding: 16px 16px 16px 16px;
    border-radius: 16px;
  }
}

@media only screen and (max-width: 430px) {
  .mapContainer {
    width: auto;
    height: auto;
    padding: 0px 32px 0px 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
  }

  .map-container {
    height: 100%;
    width: 100%;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .map-container1 {
    height: 50%;
    width: 340px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
  }

  .map-container2 {
    height: 100%;
    width: 100%;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    /* background-color: #171616; */
    align-items: center;
  }

  .mapTxt {
    font-size: 36px;
    font-family: open sans;
    font-weight: 600;
    color: rgb(88, 104, 70);
    margin: 0px 0px 0px 0px;
  }

  .mapText1 {
    width: 340px;
    border: none;
    background-color: #fff9;
    margin: 16px 0px 0px 0px;
    height: 40px;
    font-size: 14px;
    padding: 0px 0px 0px 16px;
    border-radius: 16px;
  }

  .mapTxtArea {
    width: 320px;
    border: none;
    background-color: #fff9;
    margin: 16px 0px 0px 0px;
    font-size: 16px;
    padding: 16px 16px 16px 16px;
    border-radius: 16px;
  }
}

@media only screen and (max-width: 370px) {
  .mapContainer {
    width: auto;
    height: auto;
    padding: 0px 32px 0px 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
  }

  .map-container {
    height: 100%;
    width: 100%;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .map-container1 {
    height: 50%;
    width: 100%;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
  }

  .map-container2 {
    height: 100%;
    width: 100%;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    /* background-color: #171616; */
    align-items: center;
  }

  .mapTxt {
    font-size: 36px;
    font-family: open sans;
    font-weight: 600;
    color: rgb(88, 104, 70);
    margin: 0px 0px 0px 0px;
  }

  .mapText1 {
    width: 340px;
    border: none;
    background-color: #fff9;
    margin: 16px 0px 0px 0px;
    height: 40px;
    font-size: 14px;
    padding: 0px 0px 0px 16px;
    border-radius: 16px;
  }

  .mapTxtArea {
    width: 320px;
    border: none;
    background-color: #fff9;
    margin: 16px 0px 0px 0px;
    font-size: 16px;
    padding: 16px 16px 16px 16px;
    border-radius: 16px;
  }
}
