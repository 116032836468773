.about1-container {
  background-color: #000;
  color: white;
  padding: 45px 0px 0px 0px;
  cursor: pointer;
}

.aimMainTxt {
  color: #677c53;
  font-size: 22px;
  letter-spacing: 0.2px;
  font-weight: 500;
  font-family: open sans;
  cursor: pointer;
}

.about1-container-div {
  display: flex;
  flex-direction: column;
  padding: 0px 72px 72px 72px;
  height: auto;
  gap: 32px;
  animation: fadeInUp 2s ease-out;
  color: #343131;
  text-decoration: none;
  font-size: 20px;
  letter-spacing: 0.2px;
  font-weight: 500;
  font-family: open sans;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.about1-container-div div:nth-child(2) {
  color: #544d4d;
  text-decoration: none;
  font-size: 22px;
  letter-spacing: 0.2px;
  font-weight: 500;
  font-family: open sans;
}

.about1-container-last-div {
  display: flex;
  gap: 16px;
  width: 100%;
  justify-content: space-around;
  padding: 0px 0px 72px 0px;
  height: auto;
  animation: fadeInOut 2s ease-out;
}

@keyframes fadeInOut {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

.about1-container-last-div div:nth-child(1) {
  display: flex;
  gap: 16px;
  flex-direction: row;
  color: #677c53;
  font-family: open sans;
  font-size: 28px;
}

.about1-container-last-div div:nth-child(2) {
  display: flex;
  color: #677c53;
  font-family: open sans;
  font-size: 28px;
  flex-direction: row;
  gap: 16px;
}

@media only screen and (max-width: 430px) {
  .about1-container {
    background-color: #000;
    color: white;
    padding: 16px 0px 0px 0px;
    cursor: pointer;
  }

  .aimMainTxt {
    color: #677c53;
    font-size: 16px;
    letter-spacing: 0.2px;
    font-weight: 500;
    font-family: Roboto;
    cursor: pointer;
  }

  .about1-container-div {
    display: flex;
    flex-direction: column;
    padding: 0px 32px 32px 32px;
    height: auto;
    gap: 32px;
    animation: fadeInUp 2s ease-out;
    color: #343131;
    text-decoration: none;
    font-size: 14px;
    letter-spacing: 0.2px;
    font-weight: 500;
    font-family: Roboto;
  }

  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .about1-container-div div:nth-child(2) {
    color: #544d4d;
    text-decoration: none;
    font-size: 14px;
    letter-spacing: 0.2px;
    font-weight: 500;
    font-family: 'Times New Roman', Times, serif
  }

  .about1-container-last-div {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 0px 32px 0px;
    height: auto;
    animation: fadeInOut 2s ease-out;
  }

  @keyframes fadeInOut {
    from {
      opacity: 0;
      transform: translateX(-20px);
    }
    to {
      opacity: 1;
      transform: translateX(0px);
    }
  }

  .about1-container-last-div div:nth-child(1) {
    display: flex;
    gap: 16px;
    flex-direction: row;
    color: #677c53;
    font-family: serif;
    font-size: 14px;
  }

  .about1-container-last-div div:nth-child(2) {
    display: flex;
    color: #677c53;
    font-family: 'Times New Roman', Times, serif;
    font-size: 16px;
    flex-direction: row;
    gap: 16px;
  }
}
