.services1-container-mainPart {
  display: flex;
  justify-content: space-around;
  width: 100%;
  color: white;
  padding: 0px 0px 32px 0px;
  cursor: pointer;
}

.services1-Left {
  width: 40%;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0px 32px 0px 32px;
  animation: fadeInOut 2s ease-out;
}

@keyframes fadeInOut {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.services1-Left div:nth-child(1) {
  font-size: 26px;
  font-weight: 700;
  font-style: italic;
  font-family: open sans;
  color: rgb(45, 53, 59);
}

.services1-Left div:nth-child(2) {
  font-size: 20px;
  font-weight: 600;
  font-family: open sans;
  /* color: rgb(169, 187, 201); */
}

.services1-right {
  width: 40%;
  height: 300px;
  animation: fadeIn 2s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

.servicesImages {
  width: 100%;
  height: 100%;
  border-radius: 16px;
}

@media only screen and (max-width: 430px) {
  .services1-container-mainPart {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    width: 100%;
    color: white;
    padding: 0px 0px 16px 0px;
    cursor: pointer;
  }

  .services1-Left {
    width: 90%;
    height: 140px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 16px 16px 16px 16px;
    animation: fadeInOut 2s ease-out;
  }

  @keyframes fadeInOut {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .services1-Left div:nth-child(1) {
    font-size: 12px;
    font-weight: 600;
    font-style: italic;
    font-family: Roboto;
    color: rgb(45, 53, 59);
  }

  .services1-Left div:nth-child(2) {
    font-size: 14px;
    font-weight: 500;
    font-family: "Courier New", Courier, monospace;
    /* color: rgb(169, 187, 201); */
  }

  .services1-right {
    width: 100%;
    height: 160px;
    animation: fadeIn 2s ease-out;
    text-align: center;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateX(20px);
    }
    to {
      opacity: 1;
      transform: translateX(0px);
    }
  }

  .servicesImages {
    width: 90%;
    height: 100%;
    border-radius: 16px;
  }
}

@media only screen and (max-width: 330px) {
  .services1-container-mainPart {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    width: 100%;
    color: white;
    padding: 0px 0px 16px 0px;
    cursor: pointer;
    align-items: center;
  }

  .services1-Left {
    width: 80%;
    height: 140px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 16px 16px 16px 16px;
    animation: fadeInOut 2s ease-out;
  }

  @keyframes fadeInOut {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .services1-Left div:nth-child(1) {
    font-size: 12px;
    font-weight: 600;
    font-style: italic;
    font-family: Roboto;
    color: rgb(45, 53, 59);
  }

  .services1-Left div:nth-child(2) {
    font-size: 14px;
    font-weight: 500;
    font-family: "Courier New", Courier, monospace;
    /* color: rgb(169, 187, 201); */
  }

  .services1-right {
    width: 100%;
    height: 160px;
    animation: fadeIn 2s ease-out;
    text-align: center;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateX(20px);
    }
    to {
      opacity: 1;
      transform: translateX(0px);
    }
  }

  .servicesImages {
    width: 90%;
    height: 100%;
    border-radius: 16px;
  }
}
