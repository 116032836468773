.footerContainer {
  padding: 32px 72px 72px 72px;
  background-color: #000;
  cursor: pointer;
  display: flex;
  justify-content: space-around;
  gap: 32px;
  font-family: "Courier New", Courier, monospace;
  color: #fff;
  border-width: 2px 0px 0px 0px;
  border-color: #fff;
  border-style: solid;
  text-align: justify;
}

.footerLogo {
  width: 50%;
  height: 90%;
}

.footerDiv {
  display: flex;
  align-items: center;
  gap: 16px;
}

@media only screen and (max-width: 1440px) {
  .footerContainer {
    padding: 32px 72px 72px 72px;
    background-color: #000;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    gap: 32px;
    font-family: "Courier New", Courier, monospace;
    color: #fff;
    border-width: 2px 0px 0px 0px;
    border-color: #fff;
    border-style: solid;
    text-align: justify;
  }
}

@media only screen and (max-width: 430px) {
  .footerContainer {
    padding: 32px 32px 12px 32px;
    background-color: #000;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 32px;
    font-family: "Courier New", Courier, monospace;
    color: #fff;
    border-width: 2px 0px 0px 0px;
    border-color: #fff;
    border-style: solid;
    text-align: justify;
  }

  .footerLogo {
    width: 20%;
    height: 50%;
  }

  .footerDiv {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .footerText1 {
    margin: 0;
    padding: 0px 0px 4px 0px;
  }

  .footertext {
    font-size: 16px;
    margin: 0;
    padding: 0px 0px 8px 0px;
  }

  .footerFoo {
    font-size: 10px;
    text-align: center;
  }

  .footerContainerDiv {
    display: flex;
    justify-content: space-between;
  }
}
