.sectorsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
  color: #818181;
  text-decoration: none;
  font-size: 16px;
  letter-spacing: 0.2px;
  font-weight: 500;
  font-family: open sans;
  cursor: pointer;
}

.sectorsContainer div {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0px 16px 0px 16px;
}

.sectorsContainer div:hover {
  opacity: 0.8;
}

@media only screen and (max-width: 430px) {
  .sectorsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 16px;
    color: #818181;
    text-decoration: none;
    font-size: 10px;
    letter-spacing: 0.2px;
    font-weight: 400;
    font-family: open sans;
    cursor: pointer;
  }

  .sectorsContainer div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0px 16px 0px 16px;
  }

  .sectorsContainer div:hover {
    opacity: 0.8;
  }
}
