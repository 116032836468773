.aimContainer {
  padding: 72px 32px 24px 72px;
  background-color: #000;
  animation: fadeInUp 2s ease-out;
  cursor: pointer;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.aimTxt1 {
  width: 45%;
  color: #818181;
  text-decoration: none;
  font-size: 20px;
  letter-spacing: 0.2px;
  font-weight: 500;
  font-family: open sans;
  cursor: pointer;
}

.aimMainTxt {
  color: #677c53;
  font-size: 22px;
}

.aimTxt2 {
  width: 55%;
  color: #818181;
  text-decoration: none;
  font-size: 26px;
  letter-spacing: 0.2px;
  font-weight: 500;
  font-family: open sans;
  cursor: pointer;
  padding: 32px 0px 0px 0px;
}

.aimContainer div:nth-child(3) {
  padding: 32px 0px 32px 0px;
}

.aimVideoCss {
  width: 96%;
  border-radius: 16px;
  animation: fadeIn 2s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

@media only screen and (max-width: 430px) {
  .aimContainer {
    padding: 16px 16px 16px 16px;
    background-color: #000;
  }

  .aimContainer div:nth-child(3) {
    padding: 32px 0px 32px 0px;
  }

  .aimVideoCss {
    width: 100%;
    border-radius: 16px;
  }

  .aimTxt1 {
    width: 90%;
    color: #818181;
    text-decoration: none;
    font-size: 12px;
    letter-spacing: 0.2px;
    font-weight: 500;
    font-family: "Courier New", Courier, monospace;
    cursor: pointer;
    font-style: italic;
  }

  .aimMainTxt {
    color: #677c53;
    font-size: 14px;
  }

  .aimTxt2 {
    width: 100%;
    color: #818181;
    text-decoration: none;
    font-size: 14px;
    letter-spacing: 0.2px;
    font-weight: 700;
    font-family: "Courier New", Courier, monospace;
    cursor: pointer;
    padding: 32px 0px 0px 0px;
    font-style: italic;
  }
}
