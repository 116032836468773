.services1-container {
  background-color: #000;
  animation: fadeInOut 2s ease-out;
  cursor: pointer;
  padding: 0px 0px 0px 0px;
}

@keyframes fadeInOut {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.services1-div1 {
  width: 100%;
  color: #343131;
  text-decoration: none;
  font-size: 20px;
  letter-spacing: 0.2px;
  font-weight: 500;
  font-family: open sans;
  cursor: pointer;
  text-align: center;
  padding: 32px 0px 0px 0px;
}

.services1-div2 {
  width: auto;
  color: #818181;
  text-decoration: none;
  font-size: 26px;
  letter-spacing: 0.2px;
  font-weight: 500;
  font-family: open sans;
  cursor: pointer;
  padding: 32px 32px 0px 32px;
  text-align: center;
}
.serviceVideo {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 64px 0px 64px 0px;
}

.servicesVideoCss1 {
  width: 80%;
  border-radius: 16px;
}

@media screen and (max-width: 430px) {
  .services1-container {
    background-color: #000;
    animation: fadeInOut 2s ease-out;
    cursor: pointer;
    padding: 0px 32px 0px 32px;
  }

  @keyframes fadeInOut {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  .services1-div1 {
    width: 100%;
    color: #343131;
    text-decoration: none;
    font-size: 14px;
    letter-spacing: 0.2px;
    font-weight: 500;
    font-family: "Times New Roman", Times, serif;
    cursor: pointer;
    text-align: center;
    padding: 32px 0px 0px 0px;
  }

  .services1-div2 {
    width: auto;
    color: #818181;
    text-decoration: none;
    font-size: 16px;
    letter-spacing: 0.2px;
    font-weight: 500;
    font-family: "Courier New", Courier, monospace;
    cursor: pointer;
    padding: 32px 32px 0px 32px;
    text-align: center;
  }
  .serviceVideo {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 32px 0px 32px 0px;
  }

  .servicesVideoCss1 {
    width: 90%;
    border-radius: 16px;
  }
}



@media screen and (max-width: 330px) {
  .services1-container {
    background-color: #000;
    animation: fadeInOut 2s ease-out;
    cursor: pointer;
    padding: 0px 32px 0px 32px;
  }

  @keyframes fadeInOut {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  .services1-div1 {
    width: 100%;
    color: #343131;
    text-decoration: none;
    font-size: 14px;
    letter-spacing: 0.2px;
    font-weight: 500;
    font-family: "Times New Roman", Times, serif;
    cursor: pointer;
    text-align: center;
    padding: 32px 0px 0px 0px;
  }

  .services1-div2 {
    width: 100%;
    color: #818181;
    text-decoration: none;
    font-size: 16px;
    letter-spacing: 0.2px;
    font-weight: 500;
    font-family: "Courier New", Courier, monospace;
    cursor: pointer;
    padding: 32px 0px 0px 0px;
    text-align: center;
  }
  .serviceVideo {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 32px 0px 32px 0px;
  }

  .servicesVideoCss1 {
    width: 90%;
    border-radius: 16px;
  }
}
