.countContainer {
  background-color: #000;
  padding: 32px 130px 32px 130px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.countDiv {
  transform: scaleY(0);
  animation: revealAnimation 0.5s ease-in-out forwards;
}

/* Keyframe animation */
@keyframes revealAnimation {
  0% {
    opacity: 0;
    transform: scaleY(0);
  }
  100% {
    opacity: 1;
    transform: scaleY(1);
  }
}

/* Applying animation delays */
.countDiv:nth-child(1) {
  animation-delay: 0s;
}

.countDiv:nth-child(2) {
  animation-delay: 0.5s; /* Adjust as needed */
}

.countDiv:nth-child(3) {
  animation-delay: 1s; /* Adjust as needed */
}

.countDiv:nth-child(4) {
  animation-delay: 1.5s; /* Adjust as needed */
}

.countsText1 {
  font-size: 64px;
  color: #4e8246;
  padding: 4px 0px 4px 0px;
  text-align: center;
  font-weight: 700;
  letter-spacing: 0.5;
}

.countsText2 {
  font-size: 22px;
  color: #4e8246;
  padding: 4px 0px 4px 0px;
  width: 240px;
  text-align: center;
  letter-spacing: 0.2;
}

@media only screen and (max-width: 430px) {
  .countContainer {
    background-color: #000;
    padding: 0px 0px 32px 0px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }

  .countsText1 {
    font-size: 32px;
    color: #4e8246;
    padding: 4px 0px 4px 0px;
    text-align: center;
    font-weight: 700;
    letter-spacing: 0.5;
  }

  .countsText2 {
    font-size: 16px;
    color: #4e8246;
    padding: 4px 0px 4px 0px;
    width: 240px;
    text-align: center;
    letter-spacing: 0.2;
  }
}
