.services3-container {
  display: flex;
  justify-content: space-between;
  padding: 0px 58px 64px 58px;
  cursor: pointer;
}

.services3-div1 {
  padding: 0px 0px 0px 0px;
  border-radius: 16px;
}

.sdlc {
  width: 700px;
  height: 500px;
  border-radius: 16px;
}

.services3-div2 {
  width: 700px;
  padding: 0px 0px 0px 0px;
  border-radius: 16px;
}

.services3-txt1 {
  font-size: 26px;
  font-weight: 700;
  font-style: italic;
  font-family: open sans;
  color: rgb(45, 53, 59);
  text-align: center;
}

.services3-txt2 {
  font-size: 20px;
  font-weight: 600;
  font-style: italic;
  font-family: open sans;
  color: rgb(79, 88, 95);
  text-align: left;
  padding: 32px 0px 32px 0px;
}

.services3-txt3 {
  font-size: 20px;
  font-weight: 600;
  font-family: open sans;
  /* color: rgb(169, 187, 201); */
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 0px 16px 0px;
  width: 50%;
}

.services3-txt3 div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 1440px) {
  .services3-container {
    display: flex;
    justify-content: space-between;
    padding: 0px 48px 64px 48px;
  }

  .services3-div1 {
    padding: 0px 0px 0px 0px;
    border-radius: 16px;
  }

  .sdlc {
    width: 500px;
    height: 500px;
    border-radius: 16px;
  }

  .services3-div2 {
    width: 540px;
    padding: 0px 0px 0px 0px;
    border-radius: 16px;
  }
}

@media only screen and (max-width: 430px) {
  .services3-container {
    display: flex;
    justify-content: space-between;
    padding: 0px 0px 0px 0px;
    cursor: pointer;
    flex-direction: column;
  }

  .services3-div1 {
    padding: 0px 0px 0px 0px;
    border-radius: 16px;
    text-align: center;
  }

  .sdlc {
    width: 90%;
    height: 300px;
    border-radius: 16px;
  }

  .services3-div2 {
    width: 80%;
    padding: 32px 16px 32px 16px;
    border-radius: 16px;
  }

  .services3-txt1 {
    font-size: 18px;
    font-weight: 700;
    font-style: italic;
    font-family: "Courier New", Courier, monospace;
    color: rgb(45, 53, 59);
    text-align: center;
  }

  .services3-txt2 {
    font-size: 14px;
    font-weight: 500;
    font-style: italic;
    font-family: Georgia, 'Times New Roman', Times, serif;
    color: rgb(79, 88, 95);
    text-align: left;
    padding: 32px 0px 32px 0px;
  }

  .services3-txt3 {
    font-size: 12px;
    font-weight: 600;
    font-family: 'Courier New', Courier, monospace;
    /* color: rgb(169, 187, 201); */
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px 0px 16px 0px;
    width: 80%;
  }

  .services3-txt3 div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
